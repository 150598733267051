import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import { VideoIframe } from "../UI/VideoIframe"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment videoFragment on WpPage_Flexlayouts_FlexibleLayouts_Video {
    videoTitle
    headingType
    video
  }
`

const Video = ({ videoTitle, video, headingType }) => {
  return (
    <div className={`bg-pale-grey`}>
      <Container className="videoDiv bg-white pt-3 pb-7 px-lg-3 px-xl-7">        
        <SectionHeading className="mb-4 font-weight-bold text-center text-md-start videoText" type={headingType} text={videoTitle} />                
        {video && (
          <VideoIframe video={video} />
        )}
      </Container>
    </div>
  )
}

export default Video